input[type="range"] {
    background: transparent;
    appearance: none;
    height: 2px;
    border-radius: 10px;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    width: 16px; 
    height: 16px; 
    background-color: #ffffff; 
    border-radius: 50%; 
    border: 2px solid rgba(255, 255, 255, 0.5); 
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  input[type="range"]::-webkit-slider-thumb:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  input[type="range"]::-moz-range-thumb {
    width: 16px; 
    height: 16px; 
    background-color: #ffffff; 
    border-radius: 50%; 
    border: 2px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  input[type="range"]::-moz-range-thumb:hover {
    background-color: rgba(255, 255, 255, 0.8); 
  }
  
  input[type="range"]::-ms-thumb {
    width: 16px; 
    height: 16px; 
    background-color: #ffffff; 
    border-radius: 50%; 
    border: 2px solid rgba(255, 255, 255, 0.5); 
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  input[type="range"]::-ms-thumb:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
  