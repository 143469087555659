.login-page-mobile {
  background-color: #ffffff;
  border-radius: 12px;
  height: 1148px;
  overflow: hidden;
  position: relative;
  width: 600px;
}

.login-page-mobile .screenshot {
  height: 20px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 1128px;
  width: 600px;
}

.login-page-mobile .overlap-group {
  height: 601px;
  left: 6px;
  position: absolute;
  top: 387px;
  width: 571px;
}

.login-page-mobile .frame {
  height: 557px;
  left: 17px;
  position: absolute;
  top: 44px;
  width: 554px;
}

.login-page-mobile .text-wrapper-2 {
  color: #0b2b86;
  font-family: "Aleo-Bold", Helvetica;
  font-size: 28px;
  font-weight: 700;
  left: 102px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 70px;
}

.login-page-mobile .text-wrapper-3 {
  color: #0b2b86;
  font-family: "Aleo-Regular", Helvetica;
  font-size: 22px;
  font-weight: 400;
  left: 57px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 159px;
  white-space: nowrap;
}

.login-page-mobile .text-wrapper-4 {
  color: #0b2b86;
  font-family: "Aleo-Regular", Helvetica;
  font-size: 22px;
  font-weight: 400;
  left: 57px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 304px;
  white-space: nowrap;
}

.login-page-mobile .rectangle {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #807a7a;
  border-radius: 6px;
  height: 64px;
  left: 57px;
  position: absolute;
  top: 199px;
  width: 445px;
}

.login-page-mobile .rectangle-2 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #807a7a;
  border-radius: 6px;
  height: 64px;
  left: 57px;
  position: absolute;
  top: 344px;
  width: 445px;
}

.login-page-mobile .button-instance {
  background-color: #ec885d !important;
  display: flex !important;
  left: 132px !important;
  position: absolute !important;
  top: 494px !important;
  width: 295px !important;
}

.login-page-mobile .design-component-instance-node {
  flex: 1 !important;
  font-family: "Aleo-Regular", Helvetica !important;
  font-size: 18px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: normal !important;
  margin-top: unset !important;
  text-align: center !important;
  width: unset !important;
}

.login-page-mobile .info-mark {
  background-color: #152263b2;
  border-radius: 10px;
  height: 20px;
  left: 109px;
  position: absolute;
  top: 309px;
  width: 20px;
}

.login-page-mobile .text-wrapper-5 {
  color: #ffffff;
  font-family: "Avenir Next-DemiBold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 11px;
  left: 8px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3px;
  white-space: nowrap;
  width: 7px;
}

.login-page-mobile .arrow {
  height: 22px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 28px;
}

.login-page-mobile .checkbox-2 {
  align-items: center;
  display: flex;
  gap: 8px;
  left: 74px;
  padding: 4px 0px;
  position: absolute;
  top: 459px;
  width: 448px;
}

.login-page-mobile .checkbox-instance {
  position: relative !important;
}

.login-page-mobile .i-agree-to-the-terms {
  color: var(--grey-600);
  font-family: "Aleo-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.login-page-mobile .span {
  color: #6d7391;
}

.login-page-mobile .text-wrapper-6 {
  color: #f67b36;
}

.login-page-mobile .rectangle-3 {
  background-color: #ffffff;
  height: 37px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.login-page-mobile .icon {
  height: 32px;
  left: 6px;
  position: absolute;
  top: 14px;
  width: 32px;
}

.login-page-mobile .img {
  height: 383px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 600px;
}

/* Quiero proteccion movil  */

@media (min-width: 1200px) {
  .custom-text-size {
    font-size: 8.5px;
  }
} 

@media (min-width: 1440px) {
  .custom-text-size {
    font-size: 10px;
  }
}

@media (min-width: 1500px) {
  .custom-text-size {
    font-size: 12px;
  }
} 

@media (min-width: 1800px) {
  .custom-text-size {
    font-size: 13px;
  }
}


/* YA TENGO PROTECCION MOVIL  */
@media (min-width: 1024px) {
  .codigo-barras {
    margin-left:100px;
  }
  .texto-barras{
    font-size: 15px;
  }
  .gear{
    transform: translate(340%, -10%)
  }
  .gear2{
    transform: translate(662%, -18%)
  }
  .gear3{
    transform: translate(1220%, 235%)
    rotate(70deg)
  }
  .gearT{
    font-size:130px;
    color: black;
  }
  .gearT2{
    font-size:60px;
    color: black;
  }
  .gearT3{
    font-size:42px;
    color: black;
  }
} 


@media (min-width: 1200px) {
  .custom-text-size1 {
    font-size: 9px;
  }
  .texto-barras{
    font-size: 19px;
  }
  .gear{
    transform: translate(354%, -58%)
  }
  .gear2{
    transform: translate(640%, -98%)
  }
  .gear3{
    transform: translate(1140%, 75%)
    rotate(70deg)
  }
  .gearT{
    font-size:140px;
    color: black;
  }
  .gearT2{
    font-size:70px;
    color: black;
  }
  .gearT3{
    font-size:50px;
    color: black;
  }
} 

@media (min-width: 1440px) {
  .custom-text-size1 {
    font-size: 10.5px;
  }
  .margin1440 {
    margin-right:7rem;
  }

  .custom-width-laptopL {
    width: 30vw;
  }

  .codigo-barras {
    margin-left:145px;
  }
  .texto-barras{
    font-size: 20px;
  }
  .gear{
    transform: translate(540px, -15%)
  }
  .gear2{
    transform: translate(690%, -20%)
  }
  .gear3{
    transform: translate(1230%, 200%)
    rotate(70deg)
  }
  
}

@media (min-width: 1500px) {
  .custom-text-size1 {
    font-size: 12px;
  }
  .margin1440 {
    margin-right:9rem;
  }
  .custom-width-laptopXL {
    width: 32.5vw;
  }
  .codigo-barras {
    margin-left:160px;
  }
  .texto-barras{
    font-size: 20px;
  }
  .gear{
    transform: translate(555px, -15%)
  }
  .gear2{
    transform: translate(710%, -20%)
  }
  .gear3{
    transform: translate(1260%, 200%)
    rotate(70deg)
  }

} 

@media (min-width: 1800px) {
  .custom-text-size1 {
    font-size: 13px;
  }
  .custom-width-desktopL {
    width: 29vw;
  }
  .codigo-barras {
    margin-left:260px;
  }

  .texto-barras{
    font-size: 20px;
  }

  .gear{
    transform: translate(661px, -0%)
  }
  .gear2{
    transform: translate(858%, 10%)
  }
  .gear3{
    transform: translate(1470%, 240%)
    rotate(70deg)
  }

}

.bg-warning-pattern {
  background-image: repeating-linear-gradient(
    45deg,
    #000000 0,
    #000000 10px,
    #052c87 10px,
    #052c87 20px
  );
  z-index: 0;
}


.border-warning {
  border: 10px solid transparent;
}


/* ENGRANAJE */
