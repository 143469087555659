@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Gotham";
  src: url("./assets/fonts/Gotham-Book.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./assets/fonts/Gotham-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
